<!-- src/views/Home.vue -->
<template>
  <main class="h-full w-full overflow-y-auto dark:bg-gray-800">
    <div class="px-2 lg:px-4">
      <TagComponent />
      <component :is="selectedComponent" />
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import TagComponent from "@/components/TagComponent.vue";
import FourColumnsPlain from "@/components/home_layout/FourColumnsPlain.vue";
import FourColumnsCurved from "@/components/home_layout/FourColumnsCurved.vue";
import ThreeColumnsPlain from "@/components/home_layout/ThreeColumnsPlain.vue";
import ThreeColumnsCurved from "@/components/home_layout/ThreeColumnsCurved.vue";

export default {
  name: 'HomePage',
  components: {
    TagComponent,
    FourColumnsPlain,
    FourColumnsCurved,
    ThreeColumnsPlain,
    ThreeColumnsCurved
  },
  computed: {
    ...mapState('homePageStyle', {
      homePageStyle: state => state.homePageStyle,
    }),
    selectedComponent() {
      switch (this.homePageStyle) {
        case 'fourColumnsPlain':
          return FourColumnsPlain;
        case 'threeColumnsPlain':
          return FourColumnsCurved;
        case 'fourColumnsCurved':
          return ThreeColumnsPlain;
        case 'threeColumnsCurved':
          return ThreeColumnsCurved;
        default:
          return FourColumnsPlain;
      }
    },
  },

};
</script>
