<!-- src/components/Footer.vue -->
<template>
    <div class="bg-white dark:bg-gray-800">
        <div class="grid grid-cols-12 p-5">
            <div class="col-span-12 md:col-span-3 flex lg:col-span-4 flex text-center mt-3 lg:justify-center">
                <p class="dark:text-white">© ২০২৩ বাংলা এক্সপ্রেস | সর্বস্বত্ব স্বত্বাধিকার সংরক্ষিত</p>
            </div>
            <div class="col-span-12 md:col-span-2 lg:col-span-1 flex justify-center">
                <div
                    class="h-12 w-1/2 md:w-full lg:w-full flex justify-center p-1 rounded-md bg-gray-900 lg:justify-center lg:align-center">
                    <div class="flex items-center justify-center">
                        <svg class="w-7 h-7 icon icon-googleplay">
                            <use xlink:href="/assets/icons.svg#icon-googleplay"></use>
                        </svg>
                    </div>
                    <div class="flex justify-center">
                        <small class="text-white font-semibold">Get it On
                            <br>
                            Google Play</small>
                    </div>
                </div>
            </div>
            <div class="col-span-12 mt-2 md:col-span-4 lg:col-span-4 gap-1 flex justify-center lg:justify-center">
                <a href="http://www.facebook.com/banglaexpress.page" target="_blank">
                    <svg class="w-7 h-7 icon icon-facebook">
                        <use xlink:href="/assets/icons.svg#icon-facebook"></use>
                    </svg>
                </a>
                <a href="http://web.whatsapp.com/banglaexpress" target="_blank">
                    <svg class="w-7 h-7 icon icon-whatsapp">
                        <use xlink:href="/assets/icons.svg#icon-whatsapp"></use>
                    </svg>
                </a>
                <a href="https://www.instagram.com/banglaexpress" target="_blank">
                    <svg class="w-7 h-7 icon icon-instagram">
                        <use xlink:href="/assets/icons.svg#icon-instagram"></use>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/company/banglaexpress" target="_blank">
                    <svg class="w-7 h-7 icon icon-linkedin">
                        <use xlink:href="/assets/icons.svg#icon-linkedin"></use>
                    </svg>
                </a>
                <a href="https://twitter.com/banglaexpress" target="_blank">
                    <svg class="w-7 h-7 fill-blue-400 icon icon-twitter">
                        <use xlink:href="/assets/icons.svg#icon-twitter"></use>
                    </svg>
                </a>
                <a href="https://www.youtube.com/channel/banglaexpress" target="_blank">
                    <svg class="w-7 h-7 icon icon-youtube">
                        <use xlink:href="/assets/icons.svg#icon-youtube"></use>
                    </svg>
                </a>
            </div>
            <div class="col-span-12 md:col-span-3 lg:col-span-3 flex justify-center lg:justify-center">
                <a class=" text-blue-700 dark:text-white mx-1" href="terms_and_privacy.html">শর্তাবলি ও নীতিমালা</a> | <a
                    class="text-blue-700 dark:text-white mx-1" href="about_us.html">আমাদের সম্পর্কে</a>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'FooterWeb',
};
</script>