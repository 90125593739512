export const state = {
    homePageStyle: localStorage.getItem('homePageStyle') || 'fourColumnsPlain',
  };
  
  export const mutations = {
    setHomePageStyle(state, style) {
      state.homePageStyle = style;
      localStorage.setItem('homePageStyle', style);
    },
  };
  
  export const actions = {
    async selectHomePageStyle({ commit }, style) {
      commit('setHomePageStyle', style);
    },
  };
  