<template>
    <div class="w-full bg-white rounded-lg shadow-md mb-4">
        <div class="p-5 rounded-t-lg bg-gray-300 dark:bg-gray-600">
            <p class="text-lg font-bold text-center dark:text-white">সর্বশেষ খবর</p>
        </div>
        <ul class="post-number dark:bg-gray-700">
            <li v-for="(news, index) in newsList" :key="index"
                class="p-1 border-b border-gray-50 hover:bg-gray-100 dark:hover:bg-gray-300">
                <router-link :to="{ name: 'news', params: { guiv4: news.guiv4, slug: news.slug } }">
                    <div class="flex flex-row">
                        <p class="text-2xl text-bold px-5 text-gray-400 dark:text-white">{{ $utils.bengaliNumber(index + 1)
                        }}</p>
                        <p class="dark:text-white">{{ news.title }}</p>
                    </div>
                </router-link>
            </li>
        </ul>
        <div class="bg-red-100 py-2 text-center">
            <router-link :to="{ name: 'latest-news' }"
                class="w-full text-md font-semibold text-gray-600 hover:text-red-500">
                আরো দেখুন...
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarLatestNews",
    data() {
        return {
            newsList: []
        }
    },
    methods: {
        async loadNews() {
            try {
                const response = await fetch(this.api_url_endpoint + `/get_latest_news`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                this.newsList = data.slice(0, 5);
            } catch (error) {
                console.error('Error loading news:', error);
            }
        }
    },
    mounted() {
        this.loadNews();
    }
}
</script>