<!-- src/components/Header.vue -->
<template>
    <header class="z-10 py-4 bg-white shadow-md dark:bg-gray-600">
        <div class="grid grid-cols-12 lg:px-2">
            <div class="col-span-6 md:col-span-3 lg:col-span-3 flex flex-row px-2">
                <div class="block md:hidden lg:hidden">
                    <button @click="toggleOpen('category')" class="mt-1 mx-2 text-blue-400 hover:text-blue-600"
                        id="category-button2" aria-expanded="false" aria-haspopup="true">
                        <svg class="w-6 h-6 rounded-md fill-blue-500 p-1 shadow-md icon icon-button-dots">
                            <use xlink:href="/assets/icons.svg#icon-button-dots"></use>
                        </svg>
                    </button>

                    <div v-show="isCategoryOpen"
                        class="grid grid-cols-6 absolute right-1 lg:right-2 z-10 py-2 mt-4 w-96 lg:w-2/3 rounded-md bg-gray-200 font-semibold shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="category-button" tabindex="-1"
                        id="category-options">

                        <div v-for="bengaliCategory in bengaliCategories" :key="bengaliCategory.id"
                            @click="toggleOpen('category')"
                            class="col-span-2 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                            <router-link :to="{ name: 'category', params: { slug: bengaliCategory.slug } }"
                                class="text-gray-700 text-center block px-4 py-2 text-sm font-semibold cursor-pointer"
                                role="menuitem" tabindex="-1" id="menu-item-3">{{ bengaliCategory.category_name
                                }}</router-link>
                        </div>

                        <div class="col-span-6">
                            <hr class="h-px my-2 bg-gray-400 px-5 border-0 dark:bg-gray-700">
                        </div>

                        <div v-for="englishCategory in englishCategories" :key="englishCategory.id"
                            @click="toggleOpen('category')"
                            class="col-span-2 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                            <a href="categoryea25.html?id=24"
                                class="text-gray-700 text-center block px-4 py-2 text-sm font-semibold cursor-pointer"
                                role="menuitem" tabindex="-1" id="menu-item-3">{{ englishCategory.category_name }}</a>
                        </div>
                    </div>

                </div>
                <router-link :to="{ name: 'home' }">
                    <img class="w-44 mt-2 md:w-44 lg:w-52 block dark:hidden" src="/assets/img/logo-black.png" />
                    <img class="w-44 mt-2 md:w-44 lg:w-52 hidden dark:block" src="/assets/img/logo-white.png" />
                </router-link>
            </div>
            <div class="col-md-1 md:col-span-6 lg:col-span-6 hidden md:block lg:block">
                <div class="relative w-full">
                    <form @submit.prevent="submitSearch" class="flex items-center">
                        <input v-model="searchQuery" type="search" name="query"
                            class="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="সার্চ করুন...">
                        <button type="submit"
                            class="absolute inset-y-0 right-0 px-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-r-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                            <svg class="w-4 h-3 fill-white dark:text-white icon icon-search">
                                <use xlink:href="/assets/icons.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form>
                </div>
            </div>

            <div class="col-span-5 md:col-span-3 lg:col-span-3 flex flex-row justify-end gap-1 lg:gap-3 px-2">

                <div class="py-1 block md:hidden lg:hidden">
                    <button @click="toggleOpen('search')" class="rounded-md border-purple mt-1 px-2 lg:px-1">
                        <svg class="icon icon-search w-5 h-5 fill-purple-500 border-spacing-1">
                            <use xlink:href="/assets/icons.svg#icon-search"></use>
                        </svg>
                    </button>

                    <div v-show="isSearchOpen"
                        class="grid grid-cols-1 gap-4 absolute right-1 lg:right-2 z-10 py-2 mt-4 w-96 lg:w-2/3 rounded-md bg-gray-200 font-semibold shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="category-button" tabindex="-1"
                        id="category-options">
                        <form @submit.prevent="submitSearch" class="flex items-center">
                            <input v-model="searchQuery" type="search" name="query"
                                class="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="সার্চ করুন...">
                            <button type="submit"
                                class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                                <svg class="w-4 h-3 fill-white dark:text-white icon icon-search">
                                    <use xlink:href="/assets/icons.svg#icon-search"></use>
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>

                <div class="py-1">
                    <button @click="toggleDarkMode()" class="rounded-md border-purple mt-1 px-2 lg:px-1">
                        <svg v-if="darkMode"
                            class="icon icon-sun w-5 h-5 fill-purple-500 border-spacing-1 hidden dark:block">
                            <use xlink:href="/assets/icons.svg#icon-sun"></use>
                        </svg>

                        <svg v-else class="icon icon-moon moon w-5 h-5 fill-purple-500 border-spacing-1 block dark:hidden">
                            <use xlink:href="/assets/icons.svg#icon-moon"></use>
                        </svg>
                    </button>
                </div>

                <div class="flex hidden justify-end mr-2 lg:mr-0 lg:block">
                    <div class="">
                        <button @click="toggleOpen('style')"
                            class="inline-flex w-full mt-1 justify-center gap-x-1.5 rounded-md  p-1 text-xs lg:text-sm font-semibold ring-inset text-blue-400 hover:text-blue-600"
                            id="view-list" aria-expanded="false" aria-haspopup="true">
                            <svg class="w-6 h-6 fill-blue-500 icon icon-color_lens">
                                <use xlink:href="/assets/icons.svg#icon-color_lens"></use>
                            </svg>
                        </button>
                        <div v-show="isStyleOpen"
                            class="grid grid-cols-2 gap-1 absolute right-1 bg-gray-200 lg:right-2 z-10 p-2 mt-2 w-40 lg:w-40 rounded-md font-semibold shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu" aria-orientation="vertical" aria-labelledby="view-list" tabindex="-1"
                            id="view-options">
                            <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                                <a @click="selectHomePageStyle('fourColumnsPlain')"
                                    class="text-gray-700 block px-4 py-2 text-left text-sm font-semibold cursor-pointer"
                                    data-section-id="four-column-plain">
                                    <svg class="w-7 h-7 icon icon-menu1">
                                        <use xlink:href="/assets/icons.svg#icon-menu1"></use>
                                    </svg>
                                </a>
                            </div>
                            <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                                <a @click="selectHomePageStyle('fourColumnsCurved')"
                                    class="text-gray-700 block px-4 py-2 text-left text-sm font-semibold cursor-pointer"
                                    data-section-id="four-column-curved">
                                    <svg class="w-7 h-7 icon icon-menu3">
                                        <use xlink:href="/assets/icons.svg#icon-menu3"></use>
                                    </svg>
                                </a>
                            </div>
                            <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                                <a @click="selectHomePageStyle('threeColumnsPlain')"
                                    class="text-gray-700 block px-4 py-2 text-left text-sm font-semibold cursor-pointer"
                                    data-section-id="three-column-plain">
                                    <svg class="w-7 h-7 icon icon-menu2">
                                        <use xlink:href="/assets/icons.svg#icon-menu2"></use>
                                    </svg>
                                </a>
                            </div>
                            <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                                <a @click="selectHomePageStyle('threeColumnsCurved')"
                                    class="text-gray-700 block px-4 py-2 text-left text-sm font-semibold cursor-pointer"
                                    data-section-id="three-column-curved">
                                    <svg class="w-7 h-7 icon icon-menu4">
                                        <use xlink:href="/assets/icons.svg#icon-menu4"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex hidden md:block lg:block mr-2">
                    <button @click="toggleOpen('category')" class="ml-2 ring-gray-300 text-blue-400 hover:text-blue-600"
                        style="margin-top: 5px;" id="category-button" aria-expanded="false" aria-haspopup="true">
                        <svg class="w-7 h-7 rounded-md fill-blue-500 p-1 icon icon-button-dots">
                            <use xlink:href="/assets/icons.svg#icon-button-dots"></use>
                        </svg>
                    </button>

                    <div v-show="isCategoryOpen"
                        class="grid grid-cols-6 absolute right-1 lg:right-2 z-10 py-2 mt-4 w-96 lg:w-2/3 rounded-md bg-gray-200 shadow-lg ring-1 text-sm"
                        role="menu" aria-orientation="vertical" aria-labelledby="category-button" id="category-options">

                        <template v-for="bengaliCategory in bengaliCategories" :key="bengaliCategory.id">
                            <!-- Category -->
                            <router-link @click="toggleOpen('category')"
                                :to="{ name: 'category', params: { slug: bengaliCategory.slug } }"
                                class="col-span-1 flex justify-center hover:bg-white rounded-md" role="menuitem">
                                <span class=" font-bold">{{ bengaliCategory.category_name }}</span>
                            </router-link>

                            <!-- Subcategories -->
                            <template v-if="bengaliCategory.sub_category.length > 0">
                                <router-link @click="toggleOpen('category')"
                                    v-for="bengaliSubCategory in bengaliCategory.sub_category" :key="bengaliSubCategory.id"
                                    :to="{ name: 'sub-category', params: { category: bengaliCategory.slug, slug: bengaliSubCategory.slug } }"
                                    class="col-span-1 flex justify-center hover:bg-white rounded-md" role="menuitem">
                                    <span class="">{{ bengaliSubCategory.sub_category_name }}</span>
                                </router-link>
                            </template>
                        </template>

                        <div class="col-span-6">
                            <hr class="h-px my-2 bg-gray-400 px-5 border-0 dark:bg-gray-700">
                        </div>

                        <template v-for="englishCategory in englishCategories" :key="englishCategory.id">
                            <!-- Category -->
                            <router-link @click="toggleOpen('category')"
                                :to="{ name: 'category', params: { slug: englishCategory.slug } }"
                                class="col-span-1 flex justify-center hover:bg-white rounded-md" role="menuitem">
                                <span class=" font-bold">{{ englishCategory.category_name }}</span>
                            </router-link>

                            <!-- Subcategories -->
                            <template v-if="englishCategory.sub_category.length > 0">
                                <router-link @click="toggleOpen('category')"
                                    v-for="englishSubCategory in englishCategory.sub_category" :key="englishSubCategory.id"
                                    :to="{ name: 'sub-category', params: { slug: englishSubCategory.slug } }"
                                    class="col-span-1 flex justify-center hover:bg-white rounded-md" role="menuitem">
                                    <span class="">{{ englishSubCategory.sub_category_name }}</span>
                                </router-link>
                            </template>
                        </template>

                    </div>

                </div>

                <div>
                    <button type="button" @click="toggleOpen('menu')"
                        class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 mt-1 text-xs lg:text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        id="menu-list" aria-expanded="false" aria-haspopup="true">
                        <p v-if="isLoggedIn">{{ userName }}</p>
                        <p v-else>&#9776;</p>
                    </button>
                    <div v-show="isMenuOpen"
                        class="grid grid-cols-2 gap-1 absolute right-1 lg:right-2 z-10 p-2 mt-2 w-96 lg:w-88 rounded-md bg-gray-200 font-semibold shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu" aria-orientation="vertical" aria-labelledby="menu-list" tabindex="-1" id="list-options">
                        <div class="col-span-2 lg:col-span-2 flex justify-center hover:bg-white rounded-md" role="none">
                            <template v-if="isLoggedIn">
                                <button @click="logout"
                                    class="text-gray-700 block p-2 text-left text-sm font-semibold cursor-pointer">
                                    Logout
                                </button>
                            </template>
                            <template v-else>
                                <router-link :to="{ name: 'login' }" @click="toggleOpen('menu')"
                                    class="text-gray-700 block p-2 text-left text-sm font-semibold cursor-pointer">
                                    Login
                                </router-link>
                            </template>
                        </div>

                        <div class="col-span-2">
                            <hr class="h-px my-2 bg-gray-400 px-5 border-0 dark:bg-gray-700">
                        </div>
                        <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                            <a class="text-gray-700 block p-2 text-left text-sm font-semibold cursor-pointer"
                                href="terms_and_privacy.html">শর্তাবলি ও নীতিমালা</a>
                        </div>
                        <div class="col-span-1 lg:col-span-1 flex justify-center hover:bg-white rounded-md" role="none">
                            <a class="text-gray-700 block p-2 text-left text-sm font-semibold cursor-pointer"
                                href="about_us.html">আমাদের
                                সম্পর্কে</a>
                        </div>
                        <div class="col-span-2">
                            <hr class="h-px my-2 bg-gray-400 px-5 border-0 dark:bg-gray-700">
                        </div>
                        <div class="col-span-1 lg:col-span-1 flex justify-center rounded-md">
                            <div
                                class="cursor-pointer h-12 w-full flex justify-center p-1 rounded-md lg:justify-center lg:align-center">
                                <div class="flex items-center justify-center">
                                    <svg class="w-7 h-7 icon icon-googleplay">
                                        <use xlink:href="/assets/icons.svg#icon-googleplay"></use>
                                    </svg>
                                </div>
                                <div class="flex justify-center">
                                    <small class="font-semibold">Get it On
                                        <br>
                                        Google Play
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1 lg:col-span-1 p-2 gap-1 flex justify-cente rounded-md" role="none">
                            <a href="http://www.facebook.com/banglaexpress.page" target="_blank">
                                <svg class="w-6 h-6 icon icon-facebook">
                                    <use xlink:href="/assets/icons.svg#icon-facebook"></use>
                                </svg>
                            </a>
                            <a href="http://web.whatsapp.com/banglaexpress" target="_blank">
                                <svg class="w-6 h-6 icon icon-whatsapp">
                                    <use xlink:href="/assets/icons.svg#icon-whatsapp"></use>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/banglaexpress" target="_blank">
                                <svg class="w-6 h-6 icon icon-instagram">
                                    <use xlink:href="/assets/icons.svg#icon-instagram"></use>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/banglaexpress" target="_blank">
                                <svg class="w-6 h-6 icon icon-linkedin">
                                    <use xlink:href="/assets/icons.svg#icon-linkedin"></use>
                                </svg>
                            </a>
                            <a href="https://twitter.com/banglaexpress" target="_blank">
                                <svg class="w-6 h-6 fill-blue-400 icon icon-twitter">
                                    <use xlink:href="/assets/icons.svg#icon-twitter"></use>
                                </svg>
                            </a>
                            <a href="https://www.youtube.com/channel/banglaexpress" target="_blank">
                                <svg class="w-6 h-6 icon icon-youtube">
                                    <use xlink:href="/assets/icons.svg#icon-youtube"></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
  
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'HeaderWeb',
    data() {
        return {
            bengaliCategories: [],
            englishCategories: [],
            searchQuery: '',
            isStyleOpen: false,
            isCategoryOpen: false,
            isMenuOpen: false,
            isSearchOpen: false,
        }
    },
    computed: {
        ...mapState('user', ['isLoggedIn', 'userName']),
    },
    setup() {
        const store = useStore();
        const darkMode = computed(() => store.state.darkMode.isDarkMode);

        const toggleDarkMode = () => {
            store.commit('toggleDarkMode');
        };

        return { darkMode, toggleDarkMode };
    },
    methods: {
        ...mapActions('user', ['login']),
        submitSearch() {
            this.$router.push({ name: 'search', params: { search: this.searchQuery } });

            if (this.isSearchOpen == true) {
                this.isSearchOpen = false;
            }
        },
        toggleOpen(property) {
            this.isStyleOpen = property === 'style' ? !this.isStyleOpen : false;
            this.isCategoryOpen = property === 'category' ? !this.isCategoryOpen : false;
            this.isMenuOpen = property === 'menu' ? !this.isMenuOpen : false;
            this.isSearchOpen = property === 'search' ? !this.isSearchOpen : false;
        },
        async getCategories() {
            const response = await fetch(this.api_url_endpoint + `/get_category`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            this.bengaliCategories = data.bnCategories;
            this.englishCategories = data.enCategories;
        },
        async selectHomePageStyle(style) {
            await this.$store.dispatch('homePageStyle/selectHomePageStyle', style);
            this.toggleOpen('style');
        },
        logout() {
            this.$store.dispatch("user/logout");
            this.toggleOpen('menu');
        },
    },
    mounted() {
        this.getCategories();
    }
};
</script>