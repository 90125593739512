<!-- src/App.vue -->
<template>
  <div :class="{ 'dark': darkMode }">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const darkMode = computed(() => store.state.darkMode.isDarkMode);

    const toggleDarkMode = () => {
      store.commit('toggleDarkMode');
    };

    return { darkMode, toggleDarkMode };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
