<template>
    <marquee class="mt-1 hidden md:block lg:block" id="marquee" behavior="scroll" direction="left" style="text-align: center;">
        <template v-for="(bengaliCategory, index) in bengaliCategories" :key="bengaliCategory.id">
            <button @mouseover="stopMarquee" @mouseleave="startMarquee"
                @click="$router.push({ name: 'category', params: { slug: bengaliCategory.slug } })"
                class="marquee-button inline-block px-2 py-1 text-sm font-semibold text-gray-700 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring focus:ring-gray-300 transition duration-300 ease-in-out"
                role="menuitem" tabindex="-1"
                :style="{ marginRight: index !== bengaliCategories.length - 1 ? '10px' : '' }">
                {{ bengaliCategory.category_name }}
            </button>
        </template>
    </marquee>

    <div v-if="tags.length" class="grid grid-cols-4 lg:grid-cols-10 justify-end lg:mb-2" id="tags">
        <div class="col-span-4 lg:col-span-10 px-2 lg:p-0 flex justify-end">
            <div
                class="tags flex overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden gap-1 pb-3 lg:pb-0 scrollbar-none">
                <div v-for="(tag, index) in tags" :key="index"
                    class="flex-wrap flex-shrink-0 px-2 py-1 rounded-md justify-center items-center text-gray-400 dark:text-gray-200 bg-gray-100 dark:bg-gray-500">
                    <router-link class="font-semibold" :to="{ name: 'search', params: { search: tag.tag } }">
                        #{{ tag.tag }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            bengaliCategories: [],
            tags: [],
        };
    },
    mounted() {
        this.getCategories();
        this.loadTags();
    },
    methods: {
        async getCategories() {
            const response = await fetch(this.api_url_endpoint + `/get_category`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            this.bengaliCategories = data.bnCategories;
        },
        stopMarquee() {
            document.getElementById('marquee').stop();
        },
        startMarquee() {
            document.getElementById('marquee').start();
        },
        async loadTags() {
            try {
                const response = await fetch(this.api_url_endpoint + '/get_tags', {
                    method: 'GET',
                });

                const data = await response.json();
                this.tags = data;
            } catch (error) {
                console.error('Error loading tags:', error);
            }
        },
    },
};
</script>

<style scoped>
#marquee {
    overflow: hidden;
}

.marquee-button {
    background-color: #000000 !important;
    color: #fff
}

.marquee-button:hover {
    background-color: #d9b0ff !important;
    color: #000
}
</style>