// utils.js
export const bengaliNumber = (number) => {
  const bengaliNumerals = {
    0: '০',
    1: '১',
    2: '২',
    3: '৩',
    4: '৪',
    5: '৫',
    6: '৬',
    7: '৭',
    8: '৮',
    9: '৯'
  };

  return String(number).replace(/\d/g, (digit) => bengaliNumerals[digit]);
};

export const timeAgo = (timestamp) => {
  const dateObj = new Date(timestamp);
  dateObj.setHours(dateObj.getHours());

  const timeDifference = Date.now() - dateObj.getTime();
  
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;
  
  const bengaliUnits = ['সেকেন্ড', 'মিনিট', 'ঘণ্টা', 'দিন', 'সপ্তাহ', 'মাস', 'বছর'];
  
  if (timeDifference < minute) {
    const count = Math.floor(timeDifference / 1000);
    return bengaliNumber(count) + ' ' + bengaliUnits[0] + ' আগে';
  } else if (timeDifference < hour) {
    const count = Math.floor(timeDifference / minute);
    return bengaliNumber(count) + ' ' + bengaliUnits[1] + ' আগে';
  } else if (timeDifference < day) {
    const count = Math.floor(timeDifference / hour);
    return bengaliNumber(count) + ' ' + bengaliUnits[2] + ' আগে';
  } else if (timeDifference < week) {
    const count = Math.floor(timeDifference / day);
    return bengaliNumber(count) + ' ' + bengaliUnits[3] + ' আগে';
  } else if (timeDifference < month) {
    const count = Math.floor(timeDifference / week);
    return bengaliNumber(count) + ' ' + bengaliUnits[4] + ' আগে';
  } else if (timeDifference < year) {
    const count = Math.floor(timeDifference / month);
    return bengaliNumber(count) + ' ' + bengaliUnits[5] + ' আগে';
  } else {
    const count = Math.floor(timeDifference / year);
    return bengaliNumber(count) + ' ' + bengaliUnits[6] + ' আগে';
  }
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const bengaliMonths = {
    1: 'জানুয়ারি',
    2: 'ফেব্রুয়ারি',
    3: 'মার্চ',
    4: 'এপ্রিল',
    5: 'মে',
    6: 'জুন',
    7: 'জুলাই',
    8: 'আগস্ট',
    9: 'সেপ্টেম্বর',
    10: 'অক্টোবর',
    11: 'নভেম্বর',
    12: 'ডিসেম্বর'
  };

  const bengaliDays = {
    'Sunday': 'রবিবার',
    'Monday': 'সোমবার',
    'Tuesday': 'মঙ্গলবার',
    'Wednesday': 'বুধবার',
    'Thursday': 'বৃহস্পতিবার',
    'Friday': 'শুক্রবার',
    'Saturday': 'শনিবার'
  };

  const bengaliNumerals = {
    0: '০',
    1: '১',
    2: '২',
    3: '৩',
    4: '৪',
    5: '৫',
    6: '৬',
    7: '৭',
    8: '৮',
    9: '৯'
  };

  const bengaliPeriods = {
    'AM': 'এএম',
    'PM': 'পিএম'
  };

  const format = 'd F, Y h:i A';
  let formattedDate = format
    .replace('d', bengaliNumber(date.getDate()))
    .replace('F', bengaliMonths[date.getMonth() + 1])
    .replace('Y', bengaliNumber(date.getFullYear()))
    .replace('h', bengaliNumber(date.getHours() % 12 || 12))
    .replace('i', bengaliNumber(date.getMinutes()))
    .replace('A', date.getHours() < 12 ? bengaliPeriods['AM'] : bengaliPeriods['PM']);

  formattedDate = Object.entries(bengaliDays).reduce((acc, [englishDay, bengaliDay]) => {
    return acc.replace(englishDay, bengaliDay);
  }, formattedDate);

  formattedDate = Object.entries(bengaliNumerals).reduce((acc, [englishNumeral, bengaliNumeral]) => {
    return acc.replace(new RegExp(englishNumeral, 'g'), bengaliNumeral);
  }, formattedDate);

  return formattedDate;
};

export const limitTitle = (text, limit = 11) => {
  const words = text.split(' ');

  if (words.length > limit) {
    const limitedWords = words.slice(0, limit);
    return limitedWords.join(' ') + '...';
  }

  return text;
};
