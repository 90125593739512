// src/main.js
import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import * as utils from '@/utils';

store.dispatch('user/initializeUser');

const app = createApp(App);

app.config.globalProperties.api_url_endpoint =  'https://online.bangla.express/api';
app.config.globalProperties.image_cdn_endpoint =  '';

const head = createHead();
app.use(head);

app.use(router);
app.use(store);

app.config.globalProperties.$utils = utils;

app.mount('#app');
